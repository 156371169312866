.Candidatepopup-Box-Styles {
  width: 700px !important;
  height: 640px !important;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.recruiterCandidateDetailsFeild {
  display: inline-grid;
  padding: 10px 10px 25px 35px;
  height: 40px;
}

.recruiterCandidateDetailsFeild
  .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input {
  width: 19.5vw;
}

.RecruiterDetails-container {
  width: 85vw;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
}

.RecruiterDetails-Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  padding-left: 35px;
  padding-top: 25px;
}

.RecruiterDetails-Header-1 {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  padding-left: 35px;
  margin-top: 65px;
}

.recruiterDriveDetails-main-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/QuizBackgroundimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.recruiterDriveDetails-main-container .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.recruiterDriveDetails-main-container .account-menu-button {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}
