* {
  /* text-transform:lowercase; */
}

.main-container {
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/DrivesBgNew.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  margin-bottom: 0%;
}

.container {
  width: 80vw;
  /* height: 90vh; */
  margin-bottom: 19%;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
}

.container-1 {
  width: 80vw;
  height: 70vh;
  background: #ffffff;
}

.Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  padding-left: 35px;
  padding-top: 35px;
}

.tabs {
  padding-left: 1% !important;
}

.table .css-1e2bxag-MuiDataGrid-root {
  margin-top: 10px;
  height: 61.2vh;
  width: 77.5vw;
  margin-left: 2%;
  padding-right: 3%;
}

.table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  /* color: #ffffff; */
}

.tabs.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00728e !important;
}
.css-1mrn996.Mui-selected {
  color: #00728e !important;
}

.css-11yukd5-MuiTabs-indicator {
  background-color: #00728e !important;
}
.css-axpu1l {
  background-color: #00728e !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.table .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.table .css-f3jnds-MuiDataGrid-columnHeaders {
  /* background-color: #00728e; */
}

.table .css-1e2bxag-MuiDataGrid-root {
  border: #ffffff;
  /* border: 0px; */
}

.table .css-17jjc08-MuiDataGrid-footerContainer {
  justify-content: center !important;
}

.main-container .account-menu-button {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.main-container .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.AdminSno-header .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: left !important;
}

.AdminHeader_Styles {
  width: 10px !important;
  min-width: 110px !important;
}

.AdminSno1-header .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

.about-breadcrumbs .css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #fff !important;
}

.table > :not(caption) > * > * {
  padding: 0px !important;
  /* background-color: #00728e;
  color: white; */
}

.dismissed2 {
  background: #6666;
}
.table .dismissed2:hover {
  background-color: #6666 !important;
}

.table .pending-table {
  text-transform: capitalize !important;
  /* margin-left: "2%"; */
}

.AdminUnviersty_Styles {
  width: 10px !important;
  min-width: 110px !important;
  padding-left: 21px !important;
}

.css-17jjc08-MuiDataGrid-footerContainer {
  border-top: none !important;
}

.Action {
  width: auto !important;
}
.action-sub {
  width: auto !important;
}
.MuiDataGrid-virtualScrollerRenderZone {
  width: 100% !important;
}

.styleTable .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
  /* margin-top: 20px !important ; */
  /* margin-left: 12px !important;
  margin-right: 12px !important; */
}

.dismissed2 {
  width: 100% !important;
}

.dismissed1 {
  width: 100% !important;
}

.table .css-1nitb7c {
  /* background-color: rgb(0, 114, 142) !important; */
  color: white !important;
}

.tabs .css-1mrn996.Mui-selected {
  color: rgb(0, 114, 142) !important;
}

.tabs .css-axpu1l {
  background-color: rgb(0, 114, 142) !important;
}

.table .css-iclwpf .MuiDataGrid-columnSeparator {
  display: none;
}

.AdminDrives-about-breadcrumbs .css-3mf706 {
  color: white !important;
}
