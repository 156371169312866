.UploadCandidate-main-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/candidatesListRecruiterBgNew.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  margin-bottom: 0%;
  position: absolute;
  width: 100%;
}

.UploadCandidate-container {
  width: 80vw;
  height: 90vh;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
}

/* .Candidate-container-1 {
  width: 80vw;
  height: 70vh;
  background: #ffffff;
} */

.UploadCandidate-Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  /* padding-left: 35px; */
  padding-top: 20px;
}

/* .Candidate-tabs {
  padding-left: 35px !important;
} */

.uploadCandidate-table .css-1e2bxag-MuiDataGrid-root {
  margin-top: 10px;
  min-height: 350px !important;
  width: 77.5vw;
  margin-left: 35px;
  padding-right: 35px;
}

.uploadCandidate-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #ffffff;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00728e !important;
}

.css-11yukd5-MuiTabs-indicator {
  background-color: #00728e !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.uploadCandidate-table
  .css-1e2bxag-MuiDataGrid-root
  .MuiDataGrid-iconSeparator {
  display: none !important;
}

.uploadCandidate-table .css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #00728e;
}

.uploadCandidate-table .css-1e2bxag-MuiDataGrid-root {
  border: #ffffff;
  /* border: 0px; */
}

.uploadCandidate-table .css-17jjc08-MuiDataGrid-footerContainer {
  justify-content: center !important;
  border-top: none;
}

.UploadCandidate-upload-box {
  font-size: 16px;
  border-radius: 7px;
  width: 280px;
  outline: none;
  color: #000d36;
  /* margin-top: "18px"; */
  background: rgba(149, 149, 152, 0.459);
  font-family: "Poppins";
  font-size: "33px";
  font-weight: 200;
  cursor: pointer;
  padding: 4px;
}

.UploadRecruiters_Select_section {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.upload-label {
  background-color: #424242;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  /* font-size: 15px; */
  border-radius: 0 0.3rem 0.3rem 0;
  cursor: pointer;
  /* margin-top: 1rem !important; */
  border: 1.5px solid #00728e;
  border-left: 0;
  border-top-right-radius: 4px;
  width: 100%;
}

#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.content-div .account-menu-button {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.content-div .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.page-link {
  /* color: #00728e; */
}

.active > .page-link,
.page-link.active {
  /* background-color: #00728e; */
}

.sno-content {
  border-top-left-radius: 4px;
}

.content {
  border-top-right-radius: 4px;
}

.RecruiterCndidates-about-breadcrumbs .css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #fff !important;
}
.page-link:focus {
  box-shadow: none;
  background-color: none;
}

.browserFiles:focus {
  border: none;
  outline: none;
}
.uploadCandidate-table .textCapital_letter {
  text-transform: lowercase !important;
}

.uploadCandidate-table .textCapital_letter:first-letter {
  text-transform: capitalize !important;

}


.textCapital_letter.css-1e2bxag-MuiDataGrid-root.MuiDataGrid-cellContent {
  text-transform: capitalize;
}

.institutenamecapitalized{
  text-transform:uppercase !important;
  /* background-color: #000d36 !important; */
}

.MuiMenu-paper {
  height: fit-content !important;
  width: fit-content !important;
  overflow-y: scroll !important;
}


.label-select-class{
  margin-top: -12px;
  margin-left: -8px;
  min-width: 200;
}

.select-class-drive-candlist{
  margin-top: -5px;
}

.RecruiterCndidates-about-breadcrumbs .css-3mf706{
  color: white !important;
}