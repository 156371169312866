.Admin-rest-Page-Outer {
  background-image: url("../../../src/Assests/CandidateLandingPageBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 102vh;
}

.AdminCrack-It-Logo {
  background-image: url("../../../src/Assests/CrackItLogo.png");
  /* background-color: black; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.text-name {
  margin-top: 10px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25.3px;
  color: #424242;
}

.gmail-text-name {
  margin-top: 10px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 25.3px;
  color: #424242;
}

.inputBox_text-name {
  margin-top: "12px";
  margin-bottom: "7px";
  display: "flex";
  justify-content: "start";
  font-size: "14px";
  font-family: "Helvetica";
}
