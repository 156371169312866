.add-more-dropdown .MuiSvgIcon-root {
  display: none !important;
}

.edit-add-more-box .css-1hzyicp-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: transparent;
}

.edit-add-more-box .css-1hzyicp-MuiButtonBase-root-MuiButton-root:hover {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.edit-add-more-box .css-cfzacm-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: transparent;
}

.edit-add-more-box .css-1ogkjhd-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background: transparent;
}

.edit-add-more-box .css-cznoio-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background: transparent !important;
}
.css-cznoio-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: transparent !important;
}

.driveDate-field .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #000;
  /* opacity: 0.5; */
}
.valueOne {
  width: 100%;
  display: flex;
  justify-content: start;
  position: relative;
  bottom: 77px;
  left: 467px;
}

.valueTwo {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 77px;
  left: 228px;
}

.valueThree {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 77px;
  right: 8px;
}

.valueFour {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 77px;
  right: 875px;
}

.valueFive {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 77px;
  right: 585px;
}

.valueSix {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 77px;
  right: 295px;
}

.valueSeven {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 77px;
  right: 10px;
}

.defaultValue {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  top: 28px;
}

.multiselect .css-2m9kme-MuiFormControl-root {
  width: 270px !important;
  margin: auto !important;
}

.categoriesNumber {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 46%;
  top: 48%;
  background: #00728e;
  border-radius: 4px;
}

.recruitercount .css-2m9kme-MuiFormControl-root {
  width: 270px !important;
  margin: auto !important;
}

.recruitercount {
  padding-top: 0px;
  padding-left: 0px;
}

.recruitercount-1 {
  padding-top: 5px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 100px;
}


.label-select-class-recruiters{
  margin-top: -10px;
  margin-left: -8px;
  min-width: 200;
}

.select-class-drive-admin-add{
  margin-top: -5px;
}