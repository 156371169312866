.Candidatepopup-Box-Styles {
  width: 700px !important;
  height: 640px !important;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.recruiterCandidate-AdminFelidTextBox {
  display: inline-grid;
  padding: 12px 10px 0px 10px;
  /* height: 40px */
}

.recruiterCandidateLastLine-AdminFelidTextBox {
  display: inline-grid;
  padding: 12px 10px 0px 10px;
  /* height: 40px */
}

.recruiterCandidate-AdminFelidTextBox
  .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input {
  width: 19.5vw;
}

.dropDown_Boder_ColorChanges:focus {
  border-color: black !important;
}

.titlePage
{
  text-transform: capitalize !important;
}


.recruiter-AdminFelidTextBoxs {
  display: inline-grid;
  padding: 12px 10px 0px 10px;
}


  .Input_Field_Text_Box .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input::-webkit-outer-spin-button,
  .Input_Field_Text_Box .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




