.Landing-Page-Outer {
  background-image: url("../../../Assests/CandidateLandingPageBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 102vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.powered_by {
  font-size: 16px;
  line-height: 18.4px;
  text-align: center;
  font-weight: 400;
  color: #424242;
  margin-right: 5px;
}

.Crack-It-Logo {
  background-image: url("../../../Assests/CrackItLogo.png");
  /* background-color: black; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.Landing-Page {
  background-image: url("../../../Assests/CandidateLandingPageBg.png");
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.child-container {
  width: 80vw;
  height: 81vh;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  padding: 35px;
}

.mobile-Landing-Page-Outer {
  background-image: url("../../../Assests/loginmobilebg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.child-container-mob {
  background: #ffffff;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 0px 0px;
  padding: 35px;
  margin: 25px;
}

.recruiter_text {
  /* font-family: 'Helvetica'; */
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #424242;
  text-align: center;
  text-transform: capitalize;
}