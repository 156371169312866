.OnGoingCandidate-main-container {
  min-height: 140vh;
  max-height: 180vh;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/onGoingRecruiterBgNew.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  position: relative;
}

.onGoingCandidate-containers {
  width: 80vw;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  min-height: 30vh;
  margin-bottom: 2%;
}

.onGoingCandidate-Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  /* padding-left: 35px; */
  padding-top: 20px;
}

/* .Candidate-tabs {
  padding-left: 35px !important;
} */

/* .Candidate-table .css-1e2bxag-MuiDataGrid-root {
  margin-top: 10px;
  min-height: 320px !important;
  width: 77.5vw;
  margin-left: 35px;
  padding-right: 35px;
} */

/* .Candidate-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #ffffff;
} */

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00728e !important;
}

.css-11yukd5-MuiTabs-indicator {
  background-color: #00728e !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

/* .Candidate-table .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
} */

/* .Candidate-table .css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #00728e;
} */

/* .Candidate-table .css-1e2bxag-MuiDataGrid-root {
  border: #ffffff;
} */

/* .Candidate-table .css-17jjc08-MuiDataGrid-footerContainer {
  justify-content: center !important;
  border-top: none;
} */

/* .Candidate-upload-box {
  font-size: 16px;
  border-radius: 7px;
  width: 280px;
  outline: none;
  color: #000d36;
  background: rgba(149, 149, 152, 0.459);
  font-family: "Poppins";
  font-size: "33px";
  font-weight: 200;
  cursor: pointer;
  padding: 4px;
} */
/* ::-webkit-file-upload-button{
      color: #fff;
      background: #000d36;
      padding: 8px;
      margin: 0px 15px 0px 0px;
      border: none;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      outline: none;
      font-family: "Poppins";
      cursor: pointer;
  } */

.onGoingRecruiters_Select_section {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

/* .upload-label {
  background-color: #424242;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
 
  border-radius: 0 0.3rem 0.3rem 0;
  cursor: pointer;
 
  border: 1.5px solid #00728e;
  border-left: 0;
  border-top-right-radius: 4px;
} */

#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.content-div .account-menu-button {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.content-div .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.ModalCandidateName {
  font-size: 1.4rem;
  font-weight: bold;
}

.Modaldetails {
  margin-top: 10px;
}

.CandidateDetailsHeading {
  font-size: 0.8rem;
  font-weight: bold;
}

.gm-style .gm-style-iw-c {
  background-color: #dfd8d8 !important;
  overflow: hidden !important;
  min-height: 280px !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}

.AddressHeader {
  font-size: 0.8rem;
  font-weight: bold;
  color: grey;
}

.CoridinateDivLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.CoordinateDiv {
  padding-top: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  color: grey;
}

.LocationError {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1.6rem;
  /* font-size: medium; */
  font-weight: bold;
}

.ModalAddress {
  padding: 0px 20px 0px 20px;
  background-color: white;
  border-radius: 10px;
}

.page-link {
  color: #00728e;
}

.active>.page-link,
.page-link.active {
  background-color: #00728e;
}

.sno-content {
  border-top-left-radius: 4px;
}

.content {
  border-top-right-radius: 4px;
}

.onGoingRecruiterCndidates-about-breadcrumbs .css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #fff !important;
}

.page-link:focus {
  box-shadow: none;
  background-color: none;
}

.browserFiles:focus {
  border: none;
  outline: none;
}

.onGoingCandidate-containers .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  padding-top: 20px !important;
  padding-right: 35px;
  padding-left: 35px;
  box-shadow: none !important;
}

.onGoingCandidate-containers .css-1q1u3t4-MuiTableRow-root {
  /* background-color: #00728e; */
  color: white !important;
}

.onGoingCandidate-containers .css-6qfsqn-MuiTableCell-root {
  border-bottom: none !important;
}

.onGoingerror-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #424242;
}

.label-select-class-ongoing {
  margin-top: -10px;
  margin-left: -8px;
  min-width: 200;
}

.select-class-drive-ongoing {
  margin-top: -5px;
}

.OngoingDriveTrackTableHeader {
  width: 94%;
  margin: 10px 24px 0px 30px;
}

.ongoingTable {
  border: 1px solid #e0e0e0 !important;
}

.onGoingRecruiterCndidates-about-breadcrumbs .css-3mf706 {
  color: white !important;
}