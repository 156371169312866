.Candidate-main-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/hiredCandidatesRecruiterBgNew.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.Candidate-container {
  width: 80vw;
  height: 90vh;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
}

.Candidate-container-1 {
  width: 80vw;
  height: 70vh;
  background: #ffffff;
}

.Candidate-Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  /* padding-left: 35px; */
  padding-top: 20px;
}

.Candidate-tabs {
  padding-left: 35px !important;
}

.Candidate-table .css-1e2bxag-MuiDataGrid-root {
  margin-top: 10px;
  height: 380px !important;
  width: 77.5vw;
  margin-left: 35px;
  padding-right: 35px;
}

.Candidate-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #ffffff;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00728e !important;
}

.css-11yukd5-MuiTabs-indicator {
  background-color: #00728e !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.Candidate-table .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.Candidate-table .css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #00728e;
}

.Candidate-table .css-1e2bxag-MuiDataGrid-root {
  border: #ffffff;
  /* border: 0px; */
}

.Candidate-table .css-17jjc08-MuiDataGrid-footerContainer {
  justify-content: center !important;
  border-top: none;
}

.Candidate-upload-box {
  font-size: 16px;
  border-radius: 7px;
  width: 280px;
  outline: none;
  color: #000d36;
  /* margin-top: "18px"; */
  background: rgba(149, 149, 152, 0.459);
  font-family: "Poppins";
  font-size: "33px";
  font-weight: 200;
  cursor: pointer;
  padding: 4px;
}
/* ::-webkit-file-upload-button{
      color: #fff;
      background: #000d36;
      padding: 8px;
      margin: 0px 15px 0px 0px;
      border: none;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      outline: none;
      font-family: "Poppins";
      cursor: pointer;
  } */

.Recruiters_Select_section {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.upload-label {
  background-color: #424242;
  color: white;
  padding: 0.55rem;
  font-family: sans-serif;
  /* font-size: 15px; */
  border-radius: 0 0.3rem 0.3rem 0;
  cursor: pointer;
  /* margin-top: 1rem !important; */
  border: 1.5px solid #00728e;
  border-left: 0;
  border-top-right-radius: 4px;
}

#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.content-div .account-menu-button {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.content-div .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.page-link {
  color: #00728e;
}

.active > .page-link,
.page-link.active {
  background-color: #00728e;
}
