.button_text_styles {
  text-transform: "capitalize" !important;
}

/* .pagination_styles{
    justify-content: "center" !important;
    align-items: "center" !important;
} */

.tables .css-17jjc08-MuiDataGrid-footerContainer {
  display: flex !important;
  justify-content: center !important;
  border: 0px;
}

.tables .css-1e2bxag-MuiDataGrid-root {
  /* border: #ffffff; */
}

.tables .LinerRemove .css-1nitb7c {
  background-color: rgb(0, 114, 142) !important;
  color: white !important;
}

.super-app-theme--header {
  background-color: rgb(0, 114, 142) !important;
  color: white !important;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #00728e !important;
  /* min-height: 48px !important;
    max-height: 42px !important; */
  line-height: 56px !important;
  color: white;
}

.LinerRemove .MuiDataGrid-iconSeparator {
  display: none !important;
}

.recruiter-main-container {
   height: 100vh; 
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/recruitersAdminBgNew.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  margin-bottom: 0%;
  
}

.AdminRecruiter-about-breadcrumbs .css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #fff !important;
}

.recruiter-container {
  width: 80vw;
  /* height: 90vh; */
  margin-bottom: 19%;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
}

.recruiter-Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  padding-left: 35px;
  padding-top: 35px;
}

/* .tables .css-1e2bxag-MuiDataGrid-root{
    margin-top: 10px;
    height: 69.5vh;
    width: 77.5vw; 
    margin-left: 35px;
    padding-right: 35px;
 } */

/* .LinerRemove tr:nth-child(odd) {
  background-color: #f5f5f5;
}
.LinerRemove tr:nth-child(even) {
  background-color: #ffff;
} */

.recruiter-main-container .account-menu-button {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.recruiter-main-container .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.tables .pending-table {
  text-transform: capitalize !important;
}

.tables .css-k008qs {
  color: white;
  background-color: #00728e;
}

.AdminRecruiter-about-breadcrumbs .css-3mf706 {
  color: white !important;
}

.tables .css-iclwpf.MuiDataGrid-autoHeight {
  border: none;
}
