/* .Edit-popup-Box-Styles{
    width: 739px !important;
    height: 346px !important;
} */

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.recruiterEdit-AdminFelidTextBox {
  display: inline-grid;
  /* padding: 0px 10px 45px 10px; */
  /* margin-bottom:40px; */
}

.recruiterEdit-AdminFelidTextBox
  .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input {
  width: 19.5vw;
}

.MuiOutlinedInput-input {
  padding: 11px 14px !important;
}
