.Landing-Page-Outer {
  background-image: url("../../../src/Assests/CandidateLandingPageBg.png");
  /* background-repeat: no-repeat; */
  background-size: cover;
  height: 102vh;
  overflow-y: hidden;
}

.Crack-It-Logo {
  background-image: url("../../../src/Assests/CrackItLogo.png");
  /* background-color: black; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.css-k4qjio-MuiFormHelperText-root {
  margin-left: 0px !important;
}
/* 
.Landing-Page-Outer {
  background-image: url("../../../src/Assests/CandidateLandingPageBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 102vh;
} */

/* .Crack-It-Logo {
  background-image: url("../../../src/Assests/CrackItLogo.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
} */

.Admintext-name {
  margin-top: 10px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25.3px;
  color: #424242;
}

.forgotPassword_text {
  color: #00728e;
  font-weight: 500;
  padding-left: 40px;
  font-size: 12px;
  padding-top: 4px;
  text-decoration: none !important;
}

.Landing-Page-Outer .css-19an3eb-MuiPaper-root-MuiCard-root {
  margin-top: 5.3% !important;
}


.Landing-Page-Outer .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  background: #FFFFFF;
  border: 1px solid rgba(0, 114, 142, 0.4);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.adminLoginHeadingThree{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #424242;

}