.content_text {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #424242;
    margin: 20px 0px 0px 0px;
    width: 100%;
}

.form__div {
    margin: 28px 28px 12px 28px;
}

.candidate-Landing-Page-Outer-fresher {
    background-image: url("../../../Assests/CandidateLandingPageBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* height: 160vh; */
    min-height: 170vh;
}

.candidate-Landing-Page-Outer-trained {
    background-image: url("../../../Assests/CandidateLandingPageBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* height: 130vh; */
    min-height: 150vh;
}

.candidate-Landing-Page-Outer-expired {
    background-image: url("../../../Assests/CandidateLandingPageBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.form__div .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,
.form__div .PrivateNotchedOutline-root-2.MuiOutlinedInput-notchedOutline {
    border: 1.5px solid #00728E;
    border-radius: 4px;
}

.trained_inputfields {
    width: 100%;
}

.trained_inputfields-grid {
    width: 100%;
}

.expired_box {
    margin: 5% auto;
    height: 60vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #00728E;
    background: #fff;
}

.expired_text {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: #424242;
}

.success_msg {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: #424242;
    text-align: center;
    text-transform: capitalize;
}

.fresher__inputs {
    width: 100%;
}

.powered_by {
    font-size: 16px;
    line-height: 18.4px;
    text-align: center;
    font-weight: 400;
    color: #424242;
    margin-right: 5px;
}

.recruiter_text {
    /* font-family: 'Helvetica'; */
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #424242;
    text-align: center;
    text-transform: capitalize;
}

.privacy_checkbox .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: #00728E;
}

.privacy_text span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #00728E;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}

.privacy_text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    text-align: start;
}

.privacy_header-text {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 25px !important;
    color: #000000 !important;
    text-align: start !important;
}

.privacy_modal-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    text-align: center;
}

.sub_content {
    margin-top: 16px;
}

.sub_content-secondary {
    display: flex;
    flex-direction: column;
}


.privacy_modal-text-li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    text-align: start;
    margin-top: 4px;
}

.secondary-text {
    text-align: start;
}

.second-data {
    margin-top: 14px;
}