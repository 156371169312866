.apt-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/aptitudebg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.background-image-for-quiz {
  min-height: 100vh;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.tech-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/technicalbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.verbal-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/verbalbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.reasoning-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/reasoningbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.outer-container {
  width: 80vw;
  padding-bottom: 30px;
  height: auto;
  /* height: 90vh; */
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 5%;
}

.question-tag {
  display: flex;
  justify-content: flex-start;
}

.question-no {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  /* font-size: 24px; */
  line-height: 28px;
  /* identical to box height */
  color: #424242;
  padding-top: 35px;
  padding-left: 35px;
}

.text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 16px;
  color: #424242;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 0px;
}

.quiz-buttons {
  display: flex;
  flex-direction: column;
  /* width: 275px; */
  padding-left: 35px;
  margin-top: 20px !important;
}

.toggle-Buttons {
  display: flex;
  justify-content: space-between;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 40px;
}

.button-next {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.Selected {
  background-color: #00728e !important;
  color: #fff !important;
  border-radius: 0px;
}

.NotSelected {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 0px;
}

.question-text {
  background-color: white;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.options-text {
  background-color: white;
  margin-top: -20px;
}

.quiz-options-bg-color {
  background-color: #00728e !important;
  color: #fff !important;
}

.quiz-deselcting-color {
  background-color: #fff !important;
  color: #424242 !important;
}

.quiz-deselcting-color:hover {
  background-color: #00728e !important;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25) !important;
  color: #fff !important;
}

.Quetion-content {
  width: 100%;
  display: flex;
  margin-left: 0px;
  margin-left: 0px;
  margin-bottom: -23px;
}

.aptitude-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.aptitude-text-div {
  display: none;
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .options-text {
    background-color: transparent;
    padding-bottom: 20px;
  }

  .outer-container {
    box-shadow: none;
    background-color: transparent;
  }

  .question-text {
    padding: 15px;
  }

  .questions-text {
    padding: 0px;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }

  .question-no {
    padding: 0px;
  }

  .text {
    padding: 0px;
  }

  .quiz-buttons {
    padding-left: 0px;
  }

  .quiz-options-bg-color {
    width: " -webkit-fill-available";
    height: "-webkit-fill-available";
  }

  .quiz-deselcting-color {
    width: "-webkit-fill-available";
    height: "-webkit-fill-available";
  }

  .toggle-Buttons {
    /* background-color: white; */
    padding-left: 0px;
    padding-right: 0px;
  }

  .apt-container {
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    background-image: url("../../../Assests/aptitudemobilebg.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: center !important;
  }

  .tech-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../Assests/technicalmobilebg.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
  }

  .verbal-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../Assests/verbalmobilebg.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
  }

  .reasoning-container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../Assests/reasoningmobilebg.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
  }


  .Quetion-content {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .aptitude-text-div {
    display: flex;
    padding-top: 10px;
  }

  .options-text .MuiButtonBase-root {
    width: 100%;
  }
}