.Recruiter-main-container {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/drivesRecruiterBgNew.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  padding-top: 20px;
}

.Recruiter-container {
  width: 90vw;
  margin-bottom: 19%;
  /* height: 92vh; */
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
}


.container-1 {
  width: 80vw;
  height: 70vh;
  background: #ffffff;
}

.Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  padding-left: 35px;
  padding-top: 35px;
}

.recruitertabs {
  padding-left: 35px !important;
}

.Recruiter-table .css-1e2bxag-MuiDataGrid-root {
  margin-top: 10px;
  height: 69.5vh;
  width: 77.5vw;
  margin-left: 35px;
  padding-right: 35px;
}

.Recruiter-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #ffffff;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00728e !important;
}

.css-11yukd5-MuiTabs-indicator {
  background-color: #00728e !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.Recruiter-table .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.Recruiter-table .css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #00728e;
}

.Recruiter-table .css-1e2bxag-MuiDataGrid-root {
  border: #ffffff;
  /* border: 0px; */
}

.Recruiter-table .css-17jjc08-MuiDataGrid-footerContainer {
  justify-content: center !important;
}

.Recruiter-main-container .account-menu-button {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.Recruiter-main-container .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.sno-header .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

.RecruiterDrives-about-breadcrumbs .css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #fff !important;
}

.Recruiter-table .css-17jjc08-MuiDataGrid-footerContainer {
  justify-content: center !important;
  border-top: none;
}

.dismissed2 {
  background: #6666;
}
.Recruiter-table .dismissed2:hover {
  background-color: #6666 !important;
}

.Recruiter-table .pending-tableCapital {
  text-transform: capitalize !important;
}
/* .pending-tableCapital{
  width: "150px" !important;
} */
.Recruiter-table .DriveName-Styles {
  min-width: 200px !important;
  /* max-width: 150px; */
}

.RectSno-header .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

/* .css-1nitb7c {
  background-color: #00728e;
  color: #fff;
}

.Recruiter-table .css-iclwpf{
  width: 95%;
  margin-left: 2.5%;
} */

.recrtSno-header {
  /* width: 5% !important; */
  min-width: 100px !important;
  max-width: 200px !important;
}

.unviersty_Styles {
  min-width: 180px !important;
}

.universty_Cell-Styles {
  min-width: 180px !important;
}


.Recruiter-container .recruitertabs .css-1mrn996.Mui-selected{
  color:rgb(0, 114, 142) !important;
}

.Recruiter-container .recruitertabs .css-axpu1l{
  background-color:rgb(0, 114, 142) !important;
}



.Recruiter-table .css-1nitb7c{
  background-color: rgb(0, 114, 142) !important;
  color: white !important;
}

.RecruiterDrives-about-breadcrumbs .css-3mf706{
  color: white !important;
}