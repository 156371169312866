.AdminRecruiter-AdminFelidTextBox{
    display: inline-grid ;
    padding: 0px 0px 5px 0px ;
    height: 40px;
    width: 200px;
    /* height: 40px */
}

.AdminRecruiter-AdminFelidTextBox .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input{
    width:19.5vw ;
}

.Adminmultiselect .css-2m9kme-MuiFormControl-root{
    width:225px !important;
    margin: auto !important;
}

.AdminEdit.css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px;
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width:110%
} */

.select-class-drive-candlist-edit{
    margin-top: -5px;
}