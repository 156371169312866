.greetings-main-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/QuizBackgroundimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.paper-content {
  display: grid;
  justify-content: center;
  align-items: center;
}

.tnq-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #424242;
  margin-top: 13px;
}

.completed-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #424242;
  margin-top: 15px;
}

.continue-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  color: #424242;
}

.greeting-line {
  width: 70%;
  border-bottom: 1.9px solid #00728e;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #00728e;
  margin: 30px auto;
}

.continue-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.close-icon {
  color: black;
}
