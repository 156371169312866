.Candidate-container-div {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../Assests/hiredCandidatesRecruiterBgNew.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.Candidates-container {
  width: 80vw !important;
  height: 90% !important;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 20px;
}

.container-1 {
  width: 80vw;
  height: 70vh;
  background: #ffffff;
}

.Candidates-Header {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
  /* padding-left: 35px;
    padding-top: 35px; */
}

.Candidates-tabs {
  padding: 35px !important;
}

.candiatetable .css-1e2bxag-MuiDataGrid-root {
  margin-top: 10px;
  width: 100%;
  border-radius: 0px;
  outline: none;
  border: none;
}

/* .candiatetable .css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
    color: #FFFFFF;
} */

.Candidatestable .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.completed-table-one .css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #e2e1e1 !important;
  border-radius: 0px;
}

.completed-table-two .css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #e2e1e1 !important;
  border-radius: 0px;
}
.Candidatestable .css-1e2bxag-MuiDataGrid-root {
  border: #ffffff;
  /* border: 0px; */
}

.completed_Word_styles {
  /* padding-left:657px !important; */
  color: #00728e !important;
  text-transform: capitalize;
}

.HeaderDeatail_styles {
  color: #424242;
  font-weight: 700;
  font-size: 24px;
}

.Top_Header {
  padding-top: 35px !important;
}

.tableWidth
  .MuiDataGrid-virtualScroller
  .css-1w5m2wr-MuiDataGrid-virtualScroller {
  width: 637px;
  height: 107px !important;
  margin-top: 56px;
}

.completed-tabs .MuiTab-textColorSecondary.Mui-selected {
  font-weight: 700;
  color: #000 !important;
}

.completed-task-table {
  background: #e2e1e1;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #000000 !important;
}

.completed-task-table-id-row {
  background: #f1f1f1;
}

.completed-dot {
  width: 10px;
  height: 10px;
  background: #00728e;
  border-radius: 50%;
  margin-right: 6px;
  margin-top: 2px;
}

.overall-data-found {
  background: #e2e1e1;
  padding: 19px 19px 19px 22px;
  border-radius: 0px;
}

.total-data-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.add-text-overview {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #424242;
}
