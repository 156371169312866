/* .popup-Box-Styles{
    width: 739px !important;
    height: 326px !important;
} */

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.recruiter-AdminFelidTextBox {
  display: inline-grid;
  padding: 0px 10px 40px 10px;
}

.recruiter-AdminFelidTextBox
  .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input {
  width: 19.5vw;
}

.recruiterPhoneNumberFeld {
  display: inline-grid;
  padding: 0px 10px 40px 10px;
  width: 43vh;
}
