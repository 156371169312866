.Admin-section-wrapper {
  background-image: url("../../../Assests/CandidateLandingPageBg.png");
  height: 120vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* padding: 90px 180px; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.section-name-texts {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 25.3px;
  color: #424242;
}

.recruitecard-content-text {
  padding: 25px;
  text-align: center;
}

.Admin-section-wrapper .account-menu-button {
  border: none;
  background: transparent;
  color: #424242;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}

.Admin-section-wrapper .account-menu-button:hover {
  border: none;
  background: transparent;
  color: #424242;
  text-transform: capitalize;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
}
