.quiz-section-wrapper {
  background-image: url("../../../Assests/CandidateLandingPageBg.png");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-y: hidden;
}

.section-name-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #424242;
}

.status-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #424242;
}

.duration-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #424242;
}

.card-sub-content {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}

.card-content {
  padding: 19px;
}

.quiz-back-button {
  color: #000;
  background: none;
  border: none;
  box-shadow: none;
  text-transform: capitalize;
}

.quiz-back-button:hover {
  background: none;
}

.error-text-quiz-category {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #424242;
}

@media screen and (min-width: 768px) and (max-width:1440px) {
  /* .quiz-section-wrapper {
    max-height: 648px;
    height: 100vh;
  } */
}

@media screen and (min-width: 320px) and (max-width:768px) {
  .card-content {
    padding: 10px
  }

  /* .quiz-section-wrapper {
    height: 100%;
  } */

}